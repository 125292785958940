import React from 'react';

import Layout from "components/layout/layoutPanel";
import heroWelcomeBonus from 'images/natural-gas/hero-welcomebonus.png';
import { LinkModal } from 'kh-common-components';
import { modalContents } from 'constants/modal-content';

function SignUpNgInfo (props) {

  return (
    <Layout background="mer-bg--ui-light"
        padding="mer-py-ju">
        <div className="container">
            <div className="row mer-panel feature-pill mer-bg--gs-grey-lighter">
                <div className="col-10 col-xl-5 col-md-6 col-sm-7 content mer-py-lg text-center mer-text--size-lg">
                    <h2>Save 35% off natural gas usage charges for 12 months*</h2>
                    <p className="mer-color--primary-royal"><strong>Plus, choose from a $50 Kmart or Bunnings gift card, account credit, or 10,000 Flybuys points.^</strong></p>
                    <div className="mer-panel__actions mer-button-group justify-content-center">
                        <a href="/sign-up" title="Move now" className="mer-button mer-button--primary" target="">Choose offer and sign up</a>
                    </div>
                    <br />
                    <p className="mer-typography--body2">
                        <small>
                        *Offer ends 30 April 2025. Online only. New and moving customers. &nbsp;
                            <LinkModal 
                                modalContents={modalContents}
                                useModal="true"
                                displayContent="^T&Cs apply"
                                title="Monthly Smart Saver terms & conditions"
                                modalTitle="Monthly Smart Saver terms and conditions"
                                modalContentName="plan_details_and_terms_2_summer"
                                okayButtonLabel="Close"
                            />.
                        </small>
                    </p>
                </div>
                <div className="col-6 col-xl-5 col-lg-4 col-md-5 col-sm-3 mer-panel__images feature-pill-asset ">
                    <img className="mer-panel__image"
                        src={heroWelcomeBonus}
                        alt="Welcome bonus"
                    />
                </div>
            </div>
        </div>
    </Layout>
  )
}

export default SignUpNgInfo;